@import "../../../assets/scss/variables.scss";

@mixin textEllipseMaker($width) {
    width: $width!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    white-space: nowrap!important;
}



.globalMailPage {


    .attachmentSizeExceedMsg{
        color: $error;
        font-size: 11px;
        margin-bottom: 0;
        margin-top: 0;
    }

    .pageOptions {
        color: $secondaryMain;
        border-bottom: 1px solid rgb(245, 245, 245);
        .left {
            display: flex;
            align-items: center;
            border-right: 1px solid rgb(245, 245, 245);
            padding: 8px 14px;
            min-height: 35px;
        }
        .right {
            display: flex;
            padding: 8px 14px;
            align-items: center;
            span {
                display: flex;
                align-items: center;
                margin-right: 20px;
                cursor: pointer;
                height: auto;
                .icon {
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
            
        }
    }


    $height : calc(100vh - 180px);
    .mailInnerWrapper{
        height: $height;
        overflow:hidden;
        // border:1px solid red;
        .mailLeft {
            height: $height;
            overflow: hidden;
            overflow-y: scroll;
            border-right: 1px solid rgb(245, 245, 245);
            .mailItem {
                display: flex;
                padding: 15px 4px 0 4px;
                position: relative;
                background: white;
                min-height: 105px;
                border-bottom: 1px solid #edf1f2;
                &:hover {
                    background: #F8F8F8;
                }
                &.active {
                    background: #F0F0F0;
                }
                .left {
                    padding: 0 10px;
                    // .mailItemCircle {
                    //     $size: 32px;
                    //     width: $size;
                    //     height: $size;
                    //     border-radius: 50%;
                    //     color: white;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     font-size: 6px;
                    //     font-weight: normal;
                    //     text-transform: uppercase;
                    // }
                }
                .right {
                    // padding-right: 35px;
                    width: 100%;
                    // border: 1px solid red;
                    .caption {
                        margin-top: 5px;
                        padding-right: 25px;
                        color: $grayLight;
                        @include textEllipseMaker(calc(100% - 70px));
                    }
                    .tile {
                        margin-top: 0;
                        font-size: 16px;
                        font-weight: normal;
                        margin-bottom: 0;
                        padding-right: 15px;
                        &.bold {
                            font-weight: 600;
                        }
                        @include textEllipseMaker(calc(100% - 130px));
                    }
                    .subtitle {
                        margin-top: 0;
                        margin-bottom: 0;
                        padding-right: 15px;
                        font-weight: 400;
                        @include textEllipseMaker(calc(100% - 80px));
                        &.bold{
                            font-weight: 500;
                        }
                    }

                    .absolute {
                        right: 10px;
                        position: absolute;
                        $color: #f0f0f0;
                        &.time {
                            top: 0px;
                            color: $grayLight;
                            margin-top: 14px;
                            // box-shadow: 0px 0px 10px $color;
                            // background: $color;
                        }
                        &.readIndicator {
                            top: 24px;
                            color: $grayLight;
                            margin-top: 14px;
                            // box-shadow: 1px 2px 3px $color;
                            // background: $color;
                            .icon {
                                font-size: 15px !important;
                            }
                        }
                        &.unreadCount {
                            top: 65px;
                            $size: 18px;
                            width: $size;
                            height: $size;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            background: $mailReadCount;
                            font-size: 10px;
                        }
                    }
                    &.extraPadding{
                        padding-left: 15px;
                    }
                }
            }
            .mailLoader{
                display: flex;
                justify-content: center;
                text-align: center;
                padding: 5px;
            }
        }
        .mailRight {
            // border:1px solid blue;
            height: $height;
            overflow-y: scroll;
            background: #f0f0f0;
            min-height: 100%;
            // overflow-y: scroll;
            overflow-x: hidden;
            position: relative;
            

            &.mailRightWidthFull
            {
                width: 100%;
            }
            
            .convoHead {
                .flexItem--mod {
                    display: flex;
                    align-items: center;
                    color: $TextSub !important;
                    .icon {
                        margin-right: 8px;
                        font-size: 18px;
                    }
                }
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 15px;
                background: white;
                // position: absolute;
                // top: 0;
                // width: 100%;
                // z-index: 2;
                // border-bottom: 1px solid rgb(224, 224, 224);
                &.convoHead--mod {
                }
            }
    
            .convoContent {
                padding: 8px;
                .mailContent {
                    margin-bottom: 13px;
                    padding-top: 3px;
                    padding-bottom: 3px;
                    background: white;
    
                    .MuiAccordionSummary-content {
                        margin: 0 0;
                    }
                    &.mailContentCollapsed {
                        .right {
                            .content {
                                width: 100%;
                                margin-top: 0;
                                margin-bottom: 0;
                                display: inline-block;
                            }
                        }
                        .mailContentBox {
                            margin-top: 0;
                        }
                    }

                    // background: blue;
                    &.mailIsDeleted
                    {
                        // background: red;
                        // display: none;
                        .head {
                            text-decoration: line-through;
                        }
                        .mailClosedInfoWrapper{
                            p{
                                text-decoration: line-through;
                            }
                        }
                        .nameCircle{
                            text-decoration: line-through;
                        }
                    }
                    &.mailIsDraft
                    {
                        // background: green;
                    }



                }
                .mailContentBox {
                    padding: 10px 20px;
                    display: flex;
                    // margin-top: 12px;
                    width: 100%;
                    // overflow: scroll;
                    // .mailContentCircle {
                    //     $size: 45px;
                    //     height: $size;
                    //     width: $size;
                    //     border-radius: 50%;
                    //     display: flex;
                    //     justify-content: center;
                    //     align-items: center;
                    //     font-weight: bold;
                    //     text-transform: uppercase;
                    // }
                    .right {
                        padding: 0 15px;
                        width: 100%;
                        display: flex;
                        align-items: center;

                        .rightWrapper{
                            width: 100%;
                        }

                        .divider {
                            height: 1px;
                            border-bottom: 1px dashed rgb(236, 236, 236);
                        }
                        .messageDraft{
                            font-size: 14px;
                            margin-top: 0;
                            margin-bottom: 0;
                            color: #d83b01;
                        }
                        .head {
                            margin-top: 0;
                            margin-bottom: 5px;
                            font-size: 14px;
                            display: inline-block;
                            word-break: break-all;
                            .fromAddress{
                                color: $grayLight;
                                font-weight: normal;
                                font-size: 13px;
                            }
                        }
                        .subHead {
                            margin-top: 0;
                            color: $TextSub;
                            font-size: 12px;
                            margin-bottom: 0px;
                            &.mailToWhomDetails{
                                margin-top: 0;   
                                margin-bottom: 10px;
                            }
                        }
                        // removing styles put of html tag at the time of sending mail for adjusting content in email template
                        .content{
                            overflow-x: auto;
                           
                            &.widthControl{
                                width: 95%;
                            }

                            .dontStyle{
                                width: auto!important;
                            }


                            // style{
                            //     all: initial; 
                            //     all: unset;
                            //     display: none;
                            // }

                        }
                        .attachment,
                        .mail {
                            padding-left: 0;
                            list-style: none;
                            li {
                                display: inline-block;
                                margin-right: 5px;
                            }
                            &.files {
                                display: flex;
                                flex-wrap: wrap;
                                li {
                                    border: 1px solid rgb(228, 228, 228);
                                    border-radius: 4px;
                                    padding: 4px 10px;
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;
                                }
                                .icon {
                                    font-size: 14px;
                                    margin-left: 8px;
                                }
                                a {
                                    line-height: 0;
                                    color: $TextSub;
                                }
                            }
                            &.download {
                                color: $secondaryMain;
                            }
                            &.mail {
                                color: $secondaryMain;
                                li {
                                    margin-right: 10px;
                                    cursor: pointer;
                                    font-weight: 500;
                                    &:first-child {
                                        &:after {
                                            content: "";
                                            display: inline-block;
                                            height: 11px;
                                            background: rgb(204, 204, 204);
                                            width: 1px;
                                            margin-left: 8px;
                                        }
                                    }
                                }
                            }
                        }
                        
                    }
                    &.header {
                        .content {
                            
                            margin-top: 0;
                        }
                    }
                }
    
                // overriding
                .myMail {
                    .mailContentBox {
                        padding-top: 20px;
                    }
                }
            }
        }
    }
    

    .innerLoader{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .mailReplay {
        .mailReplayFieldItem{
            padding: 10px 20px 13px 20px;
        }

        .mailReplayHead {
           
            .mailContentCircle {
                $size: 45px;
                height: $size;
                width: $size;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                text-transform: uppercase;
            }
            border-bottom: 1px solid $grayMain;
        }
        .mailReplayContent {
            // padding: 10px 20px;
            .mailReplayComposer {
                .ck-content {
                    padding: 0 21px;
                }
            }
            .ck-toolbar {
                padding-top: 6px;
            }
        }
        .mailReplayFooter {
            padding: 20px 20px;
            border-top: 1px solid $grayMain;
            display: flex;
            align-items: center;
            .attachment {
                line-height: 0;
                .icon {
                    color: $grayLight;
                    display: inline-block;
                    margin-left: 15px;
                }
            }
        }
    }

    
    @media screen and (max-width:599px)
    {
        .mailInnerWrapper{
            height: 100%;
            // overflow: auto;
            .mailLeft{
                height: 40vh!important;
                border-right: 0px solid whitesmoke!important; 
            }
            .mailRight{
                height: 40vh!important;
            }
        }
        
    }


    // &.customScrollbar{
    //     /* width */
    //     ::-webkit-scrollbar {
    //         width: 4px;
    //         height: 4px;
    //     }

    //     /* Handle */
    //     ::-webkit-scrollbar-thumb {
    //         background: $grayLight;
    //     }

    //     /* Handle on hover */
    //     ::-webkit-scrollbar-thumb:hover {
    //         background: $grayDark;
    //     }
    // }


    

}

.formWrapper{
    background: white;
    padding: 10px;
    margin: 7px 7px 0 7px;
}



.emailContentWrapper{
    // padding: 50px;
    .emailView{

        
        display: flex;
        .editorWrapper{
            margin-top: 0!important;
        }
        &.emptyContent{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 400px;
        }
    }
}
.attachmentIcon{
    cursor: pointer;
    color: $grayLight;
}


.emailPreviewModal{
    .modalFooter{
        display: block;
        &.MuiDialogActions-root{
            padding: 15px!important;
        }
        .modalFooterWrapper{
            display: flex;
            justify-content: space-between;
        }
    }
    .emailFooterPreview{
        &>div{
            max-width: 100%!important;
            border-bottom: 1px solid #ddd;
        }
    }
}


.modalPaddingRemover{
    .MuiDialogContent-root{
        padding: 0;
    }
    .customModalWrapper{
        .MuiDialogContent-root{
            padding-top: 0;
        }
    }

    .modalFooter{
        padding: 20px 18px !important;
    }
}


.messageDraftModal{
    .modalFooter{
        justify-content: flex-start;
    }
    .MuiDialog-paperFullWidth{
        max-width: 520px;
    }
    .attachmentSizeExceedMsg{
        color: $error;
        font-size: 11px;
    }

}






@media screen and (max-width: 768px) {
    .composeMailForm {
        .editorWrapper {
            padding: 35px 0px;
        }
    }
}





// @import './mailViewTemplateStyles.scss';


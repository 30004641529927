@import '../../../assets/scss/variables.scss';

.conversationBox{
    .right{
        // display: flex;
        
        .mailHeadAndForward{
            display: flex;
            justify-content: space-between;
            width: 95%;
            .headerDetails{
                width: 50%;
                overflow: hidden;
            }
            .forwardIcons{
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .icon{
                    color: #C1C1C1;
                    font-size: 21px;
                    margin-left: 10px;
                    &.replayIcon{
                        font-size: 25px;
                        position: relative;
                        top: 2px;
                    }
                    &.editIcon{
                        font-size: 17px;
                    }
                    &.deleteIcon{
                        font-size: 17px;
                    }
                }
                &.forwardIconsForMobileOnly{
                    display: none;
                }
            }
        }
    }
    
    .headerDetails{
        .head{
            margin-bottom: 0!important;
        }
        .content{
            overflow: hidden;
            white-space: nowrap;
            width: 350px;
            text-overflow: ellipsis;
            height: 20px;
            // border: 1px solid red;
            p{
                width: 266px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-top: 0;
                margin-bottom: 0;
                strong{
                    font-weight: normal;
                }
            }
            
        }
        display: flex;
        align-items: center;
    }
    .mailClosedInfo{
        text-align: right;
        color: $grayLight;
        display: flex;
        align-items: center;
        .mailClosedInfoWrapper{
            .icon{
                font-size: 18px;
            }
            p{
                margin-top: 0;
                margin-bottom: 0;
                &.marginTopAdjuster{
                    margin-top: 18px;
                }
                font-size: 14px;
            }
        }
       
    }

    .cccBccWrapper{
        position: relative;
        .cssBccItems{
            display: flex;
            p{
                margin-bottom: 0;
                margin-right: 10px;
                cursor: pointer;
            }
            position: absolute;
            top: -4px;
            right: 6px;
            color: $secondaryMain;
        }
    }
   
    &.mailIsDeleted {
        .nameCircle{
            text-decoration: none!important;
        }
    }

    .attachIcon{
        display: flex;
        align-items: center;
        p{
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 10px;
            color: $grayLight;
        }
    }
}




// .replayMailForm{
//     .cccBccWrapper{
//         .cssBccItems{
//             margin-top: 15px;
//         }
//     }
// }


@media screen and (max-width:990px)
{
    .conversationBox{
        .mailHeadAndForward{

            .headerDetails{
                display: block;
                width: 100%!important;
                .content{
                    max-width: 300px;
                }
            }
            .forwardIcons{
                margin: 0 0!important;
                display: none!important;
                &.forwardIconsForMobileOnly{
                    .icon{
                        &:first-child{
                            margin-left: 0;
                        }
                    }
                    display: flex!important;
                    width: 100%!important;
                    justify-content: flex-start!important;
                // flex-direction: row-reverse;
                }
            }
        }
        
    }
}


@media screen and (max-width:960px)
{
    .mailClosedInfo{
        justify-content: flex-start;
        .mailClosedInfoWrapper{
            display: flex;
            .icon{
                margin-right: 10px;
            }
        }
    }
    .conversationBox .mailHeadAndForward{
        flex-direction: column;
        .forwardIcons{
            margin: 5px 0;
            .icon{
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }
}

@media screen and (max-width: 868px) and (min-width:599px) {
    .conversationBox .mailHeadAndForward{
        .headerDetails{
            .content{
                width: 173px;
            }
        }
    }
}

@media screen and (max-width: 726px) and (min-width:599px) {
    .conversationBox{
        .mailContentBox{
            padding: 10px 15px!important;
        }
    }
}




// @media screen and (max-width:600px)
// {
//     .mailClosedInfo{
//         display: none;
//     }
// }
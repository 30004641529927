@import '../../../assets/scss/variables.scss';


.composeMailForm {
    &.mailForm {
        width: 100%;
        height: 100%;
        .cccBccWrapper{
            position: relative;
            .cssBccItems{
                display: flex;
                p{
                    margin-bottom: 0;
                    margin-right: 10px;
                    cursor: pointer;
                }
                position: absolute;
                top: -4px;
                right: 6px;
                color: $secondaryMain;
            }
        }
    }
   

    .editorWrapper {
        p{
            margin: 0;
        }
        .ck-editor__editable
        {
            padding: 10px 50px;
        }
        position: relative;
        padding-top: 50px;
        .ck.ck-editor {
            position: static;
        }
        .ck-editor__top {
            padding-left: 12px;
            padding-right: 12px;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            top: 0;
            border-bottom: 1px solid rgb(224, 224, 224);
        }
      
        .ck-toolbar {
            background: transparent;
            border: 1px solid transparent;
        }
        .ck-toolbar__items {
            padding-bottom: 7px;
        }

        figure.table{
            margin: 0;
            width:100%;
            table{
                border-collapse: separate;
                td{
                    border: none;
                }
            }
        }

    }

    .accordion {
        .accordionDetails {
            padding: 0;
            .accordionContent {
                padding: 25px 25px 0 25px;
            }
        }
    }
    .emailFooterSection {
        padding: 16px 25px 10px 25px;
        border-top: 1px solid rgb(224, 224, 224);
    }
    // for removing border of editable area
    .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
        border: none;
    }
    // for removing border of editable area on focus
    .ck-focused{
        border: none!important;
        box-shadow: none!important;
    }


    .fileupload{
        p{
            margin-bottom: 0;
            margin-top: 0;
        }
        margin-bottom: 10px;
    }


    // responsive emeial footer 
    .emailTemplateFooter{
        &>div{
            &:last-child{
                div{
                    &:nth-child(2){
                        // background: orange;
                        float: left!important;
                    }
                }
            }
        }
    }
    

    .attachIcon{
        display: flex;
        align-items: center;
        p{
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 10px;
            color: $grayLight;
        }
    }


    .fileAttachmentPreview{
        margin-bottom: 10px;
    }
}




@media screen and ( max-width : 962px)
{
    .composeMailForm{
        .attachmentIconWrapper{
            margin-bottom: 20px;
        }
    }
}